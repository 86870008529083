/* eslint-disable no-unused-vars */
/**
 *  @import {ProductInfo,OrderResult,JobResult,ScheduleResult} from './schdTypes.js';
 */
import dayjs from 'dayjs';
import {ValueType as exValueType, Workbook} from 'exceljs';
/**
 * @typedef {Object} excelHeader
 * @property {string} header
 * @property {string} key
 * @property {exValueType} type
 * @property {number} width
 * @property {string} [excelColKey]
 */

/**
 * @typedef {Object} excelProgressData
 * @property {number} index 序号
 * @property {number} iRowSpan 序号所控制区域的行合并
 * @property {Object[]} routeList 工序信息
 * @property {number[]} routeSpans 工序行和并
 */

/** @type {excelHeader[]} */
const prdBaseCols = [
    {header: '序号', key: 'index', type: exValueType.String, width: 8},
    {header: '订单号', key: 'order_code', type: exValueType.String, width: 15},
    {header: '产品名称', key: 'product_name', type: exValueType.String, width: 35},
    {header: '产品编码', key: 'product_code', type: exValueType.String, width: 30},
    {header: '客户编码', key: 'client', type: exValueType.String, width: 10},
    {header: '结构', key: 'stru', type: exValueType.String, width: 30},
    {header: '产品类型', key: 'product_type', type: exValueType.String, width: 10},
];
prdBaseCols.forEach(col => {
    col.excelColKey = `${col.key}`;
});
/** @type {excelHeader[]} */
const printCols = [
    {header: '计划机台', key: 'machine', type: exValueType.String, width: 15},
    {header: '工序名称', key: 'name', type: exValueType.String, width: 12},
    {header: '版宽', key: '版宽', type: exValueType.Number, width: 8},
    {header: '色数', key: '色数', type: exValueType.Number, width: 8},
    {header: '色序', key: '色序', type: exValueType.String, width: 50},
    {header: '印面', key: '印面', type: exValueType.String, width: 8},
    {header: '印刷材料', key: '材质', type: exValueType.String, width: 10},
    {header: '计划米数', key: 'quantity', type: exValueType.Number, width: 15},
    {header: '计划开工时间', key: 'pre', type: exValueType.Date, width: 15, fmt: 'HH:mm'},
    {header: '计划完工时间', key: 'end', type: exValueType.Date, width: 15, fmt: 'HH:mm'},
    {header: '实际机台', key: 'actual_machine', type: exValueType.String, width: 15},
    {header: '完成米数', key: 'actual_quantity', type: exValueType.String, width: 15},
    {header: '实际开工时间', key: 'actual_start', type: exValueType.String, width: 15},
    {header: '实际完工时间', key: 'actual_end', type: exValueType.String, width: 15},
    {header: '废品米数', key: 'actual_waste', type: exValueType.String, width: 15},
    {header: '备注', key: 'actual_remark', type: exValueType.String, width: 15},

];
printCols.forEach(col => {
    col.excelColKey = `PR${col.key}`;
});
/** @type {excelHeader[]} */
const complexColsType1 = [
    {header: '计划机台', key: 'machine_name', type: exValueType.String, width: 15},
    {header: '工序名称', key: 'name', type: exValueType.String, width: 12},
    {header: '复一材料', key: 'structure', type: exValueType.Number, width: 15},
    {header: '复一类型', key: '类型', type: exValueType.Number, width: 15},
    {header: '计划米数', key: 'quantity', type: exValueType.Number, width: 15},
    {header: '计划开工时间', key: 'pre', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '计划完工时间', key: 'end', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '实际机台', key: 'actual_machine', type: exValueType.String, width: 15},
    {header: '完成米数', key: 'actual_quantity', type: exValueType.String, width: 15},
    {header: '实际开工时间', key: 'actual_start', type: exValueType.String, width: 15},
    {header: '实际完工时间', key: 'actual_end', type: exValueType.String, width: 15},
    {header: '废品米数', key: 'actual_waste', type: exValueType.String, width: 15},
    {header: '备注', key: 'actual_remark', type: exValueType.String, width: 15},
];
/** @type {excelHeader[]} */
const complexColsType2 = complexColsType1.map(item => {
    const newItem = {...item};
    if (newItem.header === '复一材料') {
        newItem.header = '复二材料';
    }
    if (newItem.header === '复一类型') {
        newItem.header = '复二类型';
    }
    return newItem;
});
/** @type {excelHeader[]} */
const complexColsType3 = complexColsType1.map(item => {
    const newItem = {...item};
    if (newItem.header === '复一材料') {
        newItem.header = '复三材料';
    }
    if (newItem.header === '复一类型') {
        newItem.header = '复三类型';
    }
    return newItem;
});
complexColsType1.forEach(col => {
    col.excelColKey = `CP1${col.key}`;
});
complexColsType2.forEach(col => {
    col.excelColKey = `CP2${col.key}`;
});
complexColsType3.forEach(col => {
    col.excelColKey = `CP3${col.key}`;
});
/** @type {excelHeader[]} */
const splitCols = [
    {header: '计划米数', key: 'quantity', type: exValueType.Number, width: 15},
    {header: '工序名称', key: 'name', type: exValueType.String, width: 15},
    {header: '计划开工时间', key: 'pre', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '计划完工时间', key: 'end', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '完成米数', key: 'actual_quantity', type: exValueType.String, width: 15},
    {header: '实际开工时间', key: 'actual_start', type: exValueType.String, width: 15},
    {header: '实际完工时间', key: 'actual_end', type: exValueType.String, width: 15},
    {header: '废品米数', key: 'actual_waste', type: exValueType.String, width: 15},
    {header: '备注', key: 'actual_remark', type: exValueType.String, width: 15},
];
splitCols.forEach(col => {
    col.excelColKey = `SP${col.key}`;
});
/** @type {excelHeader[]} */
const mkbagCols = [
    {header: '袋型', key: '袋型', type: exValueType.Number, width: 15},
    {header: '工序名称', key: 'name', type: exValueType.String, width: 15},
    {header: '规格', key: 'standard', type: exValueType.Number, width: 15},
    {header: '结构材质', key: 'structure', type: exValueType.Number, width: 15},
    {header: '计划机台', key: 'machine_name', type: exValueType.String, width: 15},
    {header: '计划个数', key: 'quantity', type: exValueType.Number, width: 15},
    {header: '计划开工时间', key: 'pre', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '计划完工时间', key: 'end', type: exValueType.Date, width: 15, fmt: 'YYYY-MM-DD HH:mm'},
    {header: '实际机台', key: 'actual_machine', type: exValueType.String, width: 15},
    {header: '完成个数', key: 'actual_quantity', type: exValueType.String, width: 15},
    {header: '实际开工时间', key: 'actual_start', type: exValueType.String, width: 15},
    {header: '实际完工时间', key: 'actual_end', type: exValueType.String, width: 15},
    {header: '废品米数', key: 'actual_waste', type: exValueType.String, width: 15},
    {header: '备注', key: 'actual_remark', type: exValueType.String, width: 15},
];
mkbagCols.forEach(col => {
    col.excelColKey = `Mkbag${col.key}`;
});
/** @type {excelHeader[]} */
const orderCols = [
    {header: '订单计划量', key: 'quantity', type: exValueType.String, width: 15},
    {header: '实际产出量', key: 'actual_quantity', type: exValueType.String, width: 15},
    {header: '已入库', key: 'actual_instock', type: exValueType.String, width: 15},
    {header: '备注', key: 'actual_remark', type: exValueType.String, width: 15},
];
orderCols.forEach(col => {
    col.excelColKey = `Order${col.key}`;
});
const progressColHeader = [
    // 设置sheet的样式以及列宽等信息
    [
        {header: '基础信息', key: null, type: exValueType.String, colspan: prdBaseCols.length},
        {header: '印刷工序', key: null, type: exValueType.String, colspan: printCols.length},
        {header: '复一工序', key: null, type: exValueType.String, colspan: complexColsType1.length},
        {header: '复二工序', key: null, type: exValueType.String, colspan: complexColsType2.length},
        {header: '复三工序', key: null, type: exValueType.String, colspan: complexColsType3.length},
        {header: '分切工序', key: null, type: exValueType.String, colspan: splitCols.length},
        {header: '制袋工序', key: null, type: exValueType.String, colspan: mkbagCols.length},
        {header: '成品', key: null, type: exValueType.String, colspan: orderCols.length},
    ],
    [
        prdBaseCols,
        printCols,
        complexColsType1,
        complexColsType2,
        complexColsType3,
        splitCols,
        mkbagCols,
        orderCols,
    ],
];
class Process {
    constructor(data) {
        const {process, productName} = data;
        this.productName = productName;
        this.initPoperty(process);
        // 验证通用属性
        /** @type {Process[]} 直接的后工序 */
        this.directSuccessors = [];
        /** @type {Process[]} 直接的前工序 */
        this.directPredecessors = [];
        this.isMainPath = false;
        this.isMainHead = false;
    }

    initPoperty(process) {
        for (const [key, value] of Object.entries(process)) {
            if (key === 'output_ratio') {
                this[key] = value instanceof String
                    ? Number(value)
                    : value;
                continue;
            }
            if (key === '色序') {
                this.色数 = this.colorsToArray(value).length;
                this[key] = this.colorsToString(value);
                continue;
            }
            this[key] = value;
        }
    }

    /**
     * @param {string|string[]} colors
     */
    colorsToArray(colors) {
        if (Array.isArray(colors)) return colors;
        if (typeof colors === 'string') {
            return colors.split(',');
        }
        return [];
    }

    /**
     * @param {string|string[]} colors
     */
    colorsToString(colors) {
        if (Array.isArray(colors)) return colors.join(',');
        if (typeof colors === 'string') {
            return colors;
        }
        return '';
    }

    /**
     *
     * @param {string[]} keys
     */
    getPopertys(keys) {
        const _r = {};
        keys.forEach(k => {
            _r[k] = this[k];
        });
        return _r;
    }
}
class ProductRouteChart {
    /**
     *
     * @param {ProductInfo} product
     */
    constructor(product) {
        this.graph = new Map(); // 存储节点及其前序和后序节点
        this.product_name = product.name;
        this.product_code = product.code;
        this.client = product?.client || '';
        this.craft = product.craft;
        this.stru = product.stru;
        this.product_type = this.getPrdType();
        this.buildGraph(this.craft.map((process) => new Process(
            {process, productName: product.name},
        ),
        ));
        // 寻找最短的路径,设置该路径下的的工序为头工序,且该path下都设为主线程
        this.calcMainProcess();
    }

    /**
     * 提取产品类型已制袋工序为准,如果没用返回空
     */
    getPrdType() {
        const mkbag = this.craft.find(cr => {
            return cr.cate_name === '制袋';
        });
        if (mkbag) {
            return mkbag?.袋型 || '';
        }
        return '';
    }

    /**
     * @param {Process} process
     */
    addNode(process) {
        if (!this.graph.has(process.name)) {
            this.graph.set(process.name, {obj: process, predecessors: [], successors: []});
        }
    }

    /**
     * Adds a directed edge between two nodes.
     * @param {string} fromNode - The starting node.
     * @param {string} toNode - The ending node.
     */
    addEdge(fromNode, toNode) {
        if (this.graph.has(fromNode) && this.graph.has(toNode)) {
            this.graph.get(fromNode).successors.push(toNode);
            this.graph.get(toNode).predecessors.push(fromNode);
        } else {
            throw new Error(`Node not found: ${fromNode} or ${toNode}`);
        }
    }

    /**
     * Builds the graph from a list of process class list data.
     * @param {Process[]} ProcessClassList - The process data.
     */
    buildGraph(ProcessClassList) {
        ProcessClassList.forEach(process => {
            this.addNode(process);
        });
        ProcessClassList.forEach(node => {
            node.last_process.forEach(last => {
                this.addEdge(last, node.name);
            });
        });
        // 将所有工序中增加directPredecessors属性指向其前序工序,以及后序工序
        ProcessClassList.forEach(process => {
            process.directPredecessors = process.last_process.map(preProcessName => {
                return this.getProcess(preProcessName);
            });
            process.directSuccessors = this.getDirectSuccessors(process.name)
                .map(successorName => { return this.getProcess(successorName); });
        });
    }

    /**
     * 根据工序名称获取工序对象
     * @param {string} name - 工序名称
     * @returns {Process} 工序
     */
    getProcess(name) {
        const node = this.graph.get(name);
        if (!node) {
            throw new Error(`工序不存在: ${name}`);
        }
        return node.obj;
    }

    /**
     * 获取头工序,保证主工序排在第一位
     * @returns {Process[]} 头工序列表.
     */
    getStartingProcesses() {
        const headPorcess = [...this.graph.values()]
            .filter(node => node.predecessors.length === 0)
            .map(node => node.obj);
        headPorcess.sort((a, b) => (a.isMainHead ? 1 : -1) - (b.isMainHead ? 1 : -1));
        return headPorcess;
    }

    /**
     * @returns {Process} 尾工序
     * @throws 如果有多个尾工序抛出错误
     */
    getEndingProcess() {
        const endingNodes = [...this.graph.values()].filter(node => node.successors.length === 0);
        if (endingNodes.length !== 1) {
            throw new Error('尾工序数量错误');
        }
        return endingNodes[0].obj;
    }

    /**
     *
     * @param {string} processName
     * @param {string} direction -predecessors|successors
     * @returns {string[][]} -processName->next->next
     */
    findPaths(processName, direction = 'predecessors') {
        const result = [];
        const dfs = (currentNode, path) => {
            path.push(currentNode);
            const neighbors = this.graph.get(currentNode)[direction];
            if (neighbors.length === 0) {
                result.push([...path]);
            } else {
                neighbors.forEach(neighbor => dfs(neighbor, path));
            }
            path.pop();
        };
        dfs(processName, []);
        // if (direction === 'successors') {
        //     result.forEach(path => path.reverse());
        // }
        result.forEach(path => path.reverse());
        return result;
    }

    /**
     * 重新计算各个工艺路线是否为主路线
     * 规则1:线路最短的为主路线
     * 规则2:如果线路一样长,则排在第一位的为主路线
     */
    calcMainProcess() {
        const mainPath = this.fullRoute().reduce((acc, cur) => {
            return acc.length <= cur.length ? acc : cur;
        });
        for (let i = 0; i < mainPath.length; i++) {
            this.getProcess(mainPath[i]).isMainPath = true;
            if (i === mainPath.length - 1) {
                this.getProcess(mainPath[i]).isMainHead = true;
            }
        }
    }

    /**
     * 给出全部的路线图
     * @returns {string[][]} 路线图 [end->pre->pre...start]
     */
    fullRoute() {
        const endName = this.getEndingProcess().name;
        return this.findPrecedingProcesses(endName);
    }

    /**
     * 查找指定进程名之前的所有进程
     * @param processName 进程名
     * @returns {string[][]} 返回指定进程名之前的所有进程的路径
    */
    findPrecedingProcesses(processName) {
        return this.findPaths(processName, 'predecessors');
    }

    /**
     * 查找指定进程名之后的所有进程
     * @param processName 进程名
     * @returns {string[][]} 返回指定进程名之后的所有进程的路径
    */
    findSucceedingProcesses(processName) {
        return this.findPaths(processName, 'successors');
    }

    /**
     * 获取指定工序的直属前序工序
     * @param {string} processName - 工序名称
     * @returns {string[]} 直属前序工序的名称列表
     */
    getDirectPredecessors(processName) {
        const node = this.graph.get(processName);
        if (!node) {
            console.error(`工序不存在: ${processName}`);
        }
        return node.predecessors;
    }

    /**
     * 获取指定工序的直属后序工序
     * @param {string} processName - 工序名称
     * @returns {string[]} 直属后序工序的名称列表
     */
    getDirectSuccessors(processName) {
        const node = this.graph.get(processName);
        if (!node) {
            console.error(`工序不存在: ${processName}`);
            return [];
        }
        return node.successors;
    }

    /**
     * 根据指定的 cate_code 筛选节点
     * @param {string} cateCode - 要筛选的 cate_code
     * @returns {Process[]} 符合条件的工序对象列表
     */
    filterNodesByCateCode(cateCode) {
        const filteredNodes = [];

        // 遍历图中所有的节点
        this.graph.forEach((node) => {
            if (node.obj.cate_code === cateCode) {
                filteredNodes.push(node.obj);
            }
        });

        return filteredNodes;
    }

    /**
     * 打印工序的工艺路线
     */
    printGraph() {
        this.fullRoute().forEach(path => {
            console.log(path.join('-->'));
        });
    }
}

/**
 * 已产品code为key图信息map
 * @typedef {Map<string,ProductRouteChart>} ProductRouteMap
 */
/**
 * 已订单号为(order@@@product.code)的排产信息map
 * @typedef {Map<string,ScheduleResult[]>} OrderSchdsMap
 */
/**
 *
 * @param {{orderList:OrderResult[],estimateSchdList:ScheduleResult[],
 * schdList:ScheduleResult[],jobList:JobResult[],schdHistory:ScheduleResult[]}}
 * @returns {[prdChartMap:ProductRouteMap,ordersMap:OrderSchdsMap,orderQuantityMap:orderQuantityMap]}
 */
const tranStoreToProcess = ({orderList, estimateSchdList, schdList, jobList, schdHistory}) => {
    /** @type {ProductRouteMap>} -<product.code,ProductRouteMap> */
    const prdChartMap = new Map();
    /** @type {OrderSchdsMap}  */
    const ordersMap = new Map();
    const orderQuantityMap = new Map();
    for (const order of orderList) {
        // 获取相应的order_code
        if (!orderQuantityMap.has(order.order_code)) {
            orderQuantityMap.set(order.order_code, order.quantity);
        }
    }
    const _schdList = [...schdList];
    const _schdHistory = schdHistory.filter(item => item.error.length === 0);
    for (const hs of _schdHistory) {
        // 如果有精算数据,则不使用历史数据
        if (_schdList.find((sch) => sch.job_code === hs.job_code
         && sch.process_name === hs.process_name)) {
            continue;
        }
        _schdList.push(hs);
    }
    for (const schd of _schdList) {
        // 获取相应的order_code
        if (schd.error.lenth > 0) continue;
        const _job = jobList.find((job) => job.job_code === schd.job_code && job.error.length === 0);
        if (_job === undefined) continue;
        schd.order_code = _job.order_code;
        const _order = orderList.find((order) =>
            order.order_code === schd.order_code && order.error.length === 0);
        if (_order === undefined) continue;
        schd.product_info = schd.product_info ? schd.product_info : _order.product_info;
        if (!prdChartMap.has(schd.product_info.code)) {
            prdChartMap.set(schd.product_info.code, new ProductRouteChart(schd.product_info));
        }
        // 判断是否有多天的排产信息,有的话则进行日期合并
        mergeSchd(ordersMap, schd);
    }
    for (const es of estimateSchdList) {
        if (!prdChartMap.has(es.product_info.code)) {
            prdChartMap.set(es.product_info.code, new ProductRouteChart(es.product_info));
        }
        mergeSchd(ordersMap, es);
    }
    return [ordersMap, prdChartMap, orderQuantityMap];
};
/**
 *
 * @param {OrderSchdsMap} orderSchdsMap
 * @param {ProductRouteMap} prdChartMap
 * @param {Map<string,number>} orderQuantityMap
 * @returns {excelProgressData[]}
 */
const mkRouteExcelData = (orderSchdsMap, prdChartMap, orderQuantityMap) => {
    const exProgressList = [];
    let orderIndex = 1;
    for (const key of orderSchdsMap.keys()) {
        const schdList = orderSchdsMap.get(key);
        const [orderCode, prdCode] = parseOrderPrdCodes(key);
        if (!prdChartMap.has(prdCode)) continue;
        const prdRouteChart = prdChartMap.get(prdCode);
        const prdBaseExData = mkPrdExcelData(orderCode, prdRouteChart);
        /** @type {excelProgressData} */
        const exProgressData = {index: orderIndex, iRowSpan: 0, routeList: [], routeSpans: []};
        for (const route of prdRouteChart.fullRoute()) {
            // 当前线路的排产信息列表
            const routeSchdList = schdList.filter(sch => {
                return route.includes(sch.process_name);
            });
            // 分别生成印刷,复合1,复合2,复合3,分切,制袋
            const printExData = mkPrintProcessExcelData(routeSchdList, prdRouteChart);
            const cp1ExData = mkComplexType1ExcelData(routeSchdList, prdRouteChart);
            const cp2ExData = mkComplexType2ExcelData(routeSchdList, prdRouteChart);
            const cp3ExData = mkComplexType3ExcelData(routeSchdList, prdRouteChart);
            const splitExData = mkCommonProcessExcelData(routeSchdList, prdRouteChart, '分切', splitCols);
            const bagExData = mkCommonProcessExcelData(routeSchdList, prdRouteChart, '制袋', mkbagCols);
            const orderExData = [orderCols.reduce((acc, col) => {
                if (col.key === 'quantity') {
                    acc[col.excelColKey] = orderQuantityMap.get(orderCode) || '';
                } else {
                    acc[col.excelColKey] = '';
                }
                return acc;
            }, {})];
            // 将上述的data安装最大长度进行合并
            const routeData = mergeAndFillArrays(prdBaseExData, printExData, cp1ExData,
                cp2ExData, cp3ExData, bagExData, splitExData, orderExData);
            routeData.forEach(row => { row.index = orderIndex; });
            exProgressData.iRowSpan += routeData.length;
            exProgressData.routeSpans.push(routeData.length);
            exProgressData.routeList.push(...routeData);
        }
        if (exProgressData.iRowSpan > 0) {
            exProgressList.push(exProgressData);
            orderIndex += 1;
        }
    }
    return exProgressList;
};

/**
 * @param {Workbook} wb
 * @param {Object} store
 */
const writeProgressToExcel = (wb, store) => {
    const exPList = mkRouteExcelData(...tranStoreToProcess(store));
    const _hStyle = {style: 'medium', color: {argb: 'FF000000'}};
    const _cStyle = {style: 'thin', color: {argb: 'FF000000'}};
    const ws = wb.addWorksheet('进度表');
    // 左侧空余列
    const colStart = 3;
    const maxRows = 2000;
    // 设置sheet的表头信息
    ws.columns = [
        ...Array(colStart - 1).fill({header: null, key: null, width: 4}),
        ...progressColHeader[1].reduce((acc, col) => {
            acc.push(...col.map(h => { return {'key': h.excelColKey, 'header': h.header, width: h.width}; }));
            return acc;
        }, [])];
    // 设置表头空余行信息
    ws.insertRow(1);
    ws.insertRow(1);
    ws.insertRow(1);
    // 设置表头信息合并列
    const h1RowIndex = 3;
    const h2RowIndex = 4;
    // 设置冻结行和列
    ws.views = [{state: 'frozen', xSplit: ws.getColumn('product_type').number, ySplit: h2RowIndex}];
    const headerH1 = ws.getRow(h1RowIndex);
    headerH1.height = 30;
    let _clIndex = 3;
    progressColHeader[0].forEach(row => {
        headerH1.getCell(_clIndex).value = row.header;
        ws.mergeCells(h1RowIndex, _clIndex, h1RowIndex, _clIndex + row.colspan - 1);
        _clIndex += row.colspan;
    });
    headerH1.eachCell((cell) => {
        cell.border = {
            top: _hStyle,
            left: _hStyle,
            bottom: _hStyle,
            right: _hStyle,
        };
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
    });
    headerH1.commit();
    const headerH2 = ws.getRow(h2RowIndex);
    headerH2.height = 30;
    headerH2.eachCell((cell) => {
        cell.border = {
            top: _hStyle,
            left: _hStyle,
            bottom: _hStyle,
            right: _hStyle,
        };
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
    });
    headerH2.commit();
    // 写入数据
    let lastRowIndex = h2RowIndex + 1;
    for (const exp of exPList) {
        const _expStart = lastRowIndex;
        const _rs = ws.addRows(exp.routeList);
        _rs.forEach(row => {
            row.eachCell((c) => {
                c.border = {
                    top: _cStyle,
                    left: _cStyle,
                    bottom: _cStyle,
                    right: _cStyle,
                };
                c.alignment = {vertical: 'middle'};
            });
            row.height = 18;
            row.commit();
        });
        lastRowIndex += exp.iRowSpan;

        // 进行合并处理
        // pdrBasae
        for (const ex of prdBaseCols) {
            const spcol = ws.getColumn(ex.excelColKey);
            ws.mergeCells(_expStart, spcol.number, lastRowIndex - 1, spcol.number);
        }
        // 合并订单信息
        for (const ex of orderCols) {
            const spcol = ws.getColumn(ex.excelColKey);
            ws.mergeCells(_expStart, spcol.number, lastRowIndex - 1, spcol.number);
        }
        // 合并各个工序信息
        mergeProcessCells(exp, _expStart, ['PRname', 'PRmachine'], ws, printCols);
        mergeProcessCells(exp, _expStart, ['CP1name', 'CP1machine_name'], ws, complexColsType1);
        mergeProcessCells(exp, _expStart, ['CP2name', 'CP2machine_name'], ws, complexColsType2);
        mergeProcessCells(exp, _expStart, ['CP3name', 'CP3machine_name'], ws, complexColsType3);
        mergeProcessCells(exp, _expStart, ['SPname'], ws, splitCols);
        mergeProcessCells(exp, _expStart, ['Mkbagname', 'Mkbagmachine_name'], ws, mkbagCols);
        // 对每个订单的最后一行进行样式设置
        ws.lastRow.eachCell({includeEmpty: false}, (cell) => {
            const cb = cell.border || {};
            cell.border = {
                top: cb.top,
                left: cb.left,
                right: cb.right,
                bottom: {style: 'medium', color: {argb: 'FF808080'}}, // 仅设置右边框
            };
        });
    }
    // 设置各个工序列样式
    for (const pr of progressColHeader[1]) {
        const seprateCol = ws.getColumn(pr[pr.length - 1].excelColKey);
        for (let i = h1RowIndex; i < maxRows; i++) {
            const cell = ws.getCell(i, seprateCol.number);
            const currentBorder = cell.border || {};
            // 更新右侧边框样式，不覆盖其他边框
            cell.border = {
                top: currentBorder.top,
                left: currentBorder.left,
                bottom: currentBorder.bottom,
                right: {style: 'medium', color: {argb: 'FF3A3939'}}, // 仅设置右边框
            };
        }
    }
};
/**
 *
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 * @param {excelHeader[]} headers
 * @returns {object[]}
 */
const mkProcessExcelData = (schdList, prdRouteChart, headers) => {
    if (schdList.length === 0) {
        return [headers.reduce((acc, col) => {
            acc[col.excelColKey] = '';
            return acc;
        }, {})];
    }
    const result = schdList.map(sch => {
        const process = prdRouteChart.getProcess(sch.process_name);
        return headers.reduce((acc, col) => {
            acc[col.excelColKey] = getPoperty(col, sch, process);
            return acc;
        }, {});
    });
    return result;
};
/**
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 */
const mkPrintProcessExcelData = (schdList, prdRouteChart) => {
    const _schdList = schdList.filter(sch => sch.process_cate_name === '印刷');
    return mkProcessExcelData(_schdList, prdRouteChart, printCols);
};
/**
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 */
const mkCommonProcessExcelData = (schdList, prdRouteChart, cateName, header) => {
    const _schdList = schdList.filter(sch => sch.process_cate_name === cateName);
    return mkProcessExcelData(_schdList, prdRouteChart, header);
};
/**
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 */
const mkComplexType1ExcelData = (schdList, prdRouteChart) => {
    const _schdList = schdList.filter((sch) => {
        const process = prdRouteChart.getProcess(sch.process_name);
        return sch.process_cate_name === '复合' && process.复次 === '一复';
    });
    return mkProcessExcelData(_schdList, prdRouteChart, complexColsType1);
};
/**
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 */
const mkComplexType2ExcelData = (schdList, prdRouteChart) => {
    const _schdList = schdList.filter((sch) => {
        const process = prdRouteChart.getProcess(sch.process_name);
        return sch.process_cate_name === '复合' && process.复次 === '二复';
    });
    return mkProcessExcelData(_schdList, prdRouteChart, complexColsType2);
};
/**
 * @param {ScheduleResult[]} schdList
 * @param {ProductRouteChart} prdRouteChart
 */
const mkComplexType3ExcelData = (schdList, prdRouteChart) => {
    const _schdList = schdList.filter((sch) => {
        const process = prdRouteChart.getProcess(sch.process_name);
        return sch.process_cate_name === '复合' && process.复次 === '三复';
    });
    return mkProcessExcelData(_schdList, prdRouteChart, complexColsType3);
};
/**
 *
 * @param {excelHeader} excelHeader
 * @param {ScheduleResult} sch
 * @param {Process} process
 */
const getPoperty = (excelHeader, sch, process) => {
    if (Object.hasOwn(sch, excelHeader.key)) {
        const poperty = sch[excelHeader.key];
        if (['pre', 'end'].includes(excelHeader.key)) {
            return dayjs(poperty).format('MM-DD HH:mm');
        }
        return poperty;
    }
    if (process[excelHeader.key]) {
        const poperty = process[excelHeader.key];
        if (excelHeader.type === exValueType.Date) {
            return dayjs(poperty).format('MM-DD HH:mm');
        }
        return poperty;
    }
    return '';
};
/**
 *
 * @param {string} order_code
 * @param {ProductRouteChart} prdPath
 * @return {Object}
 */
const mkPrdExcelData = (order_code, prdPath) => {
    return [{
        order_code,
        product_name: prdPath.product_name,
        product_code: prdPath.product_code,
        stru: prdPath.stru,
        product_type: prdPath.product_type,
        client: prdPath.client,
    }];
};

const mergeAndFillArrays = (...arrays) => {
    // 找到数组中的最大长度
    const maxLength = Math.max(...arrays.map(arr => arr.length));

    return Array.from({length: maxLength}, (_, i) => {
        // 创建每一项的新对象，包含各个数组的字段
        return arrays.reduce((mergedObj, arr) => {
            // 如果当前索引超过数组长度，使用最后一个对象的值进行补足
            const item = arr[i] || arr[arr.length - 1] || {};
            return {...mergedObj, ...item};
        }, {});
    });
};
/**
 * 将多个schd合并到一个schd中
 * @param {Map} ordersMap
 * @param {ScheduleResult} schd
 */
const mergeSchd = (ordersMap, schd) => {
    const makeTm = (tm) => {
        const validTimes = ['HH:mm', 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss'];
        const validDate = ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss'];
        let pre = '';
        let end = '';
        if (tm.pre && dayjs(tm.pre, validTimes, true).isValid()) {
            // 提取时间部分
            if (dayjs(tm.pre, validDate, true).isValid()) {
                pre = tm.pre;
            } else {
                pre = `${tm.date} ${tm.pre}`;
            }
            // 将时间与日期结合
        } else {
            pre = tm.date;
        }
        if (tm.end && dayjs(tm.end, validTimes, true).isValid()) {
            if (dayjs(tm.end, validDate, true).isValid()) {
                end = tm.end;
            } else {
                end = `${tm.date} ${tm.end}`;
            }
        } else {
            end = tm.date;
        }
        return {pre, end};
    };
    const _key = mergeOrderPrdCodes(schd);
    if (!ordersMap.has(_key)) {
        ordersMap.set(_key, []);
    }
    const _tmp = ordersMap.get(_key).find(sch => {
        return sch.job_code === schd.job_code && sch.process_name === schd.process_name;
    });
    const {pre, end} = makeTm(schd);
    if (_tmp) {
        _tmp.pre = dayjs(_tmp.pre).subtract(dayjs(pre)) > 0
            ? pre
            : _tmp.pre;
        _tmp.quantity += schd.quantity;
        _tmp.end = dayjs(_tmp.end).subtract(dayjs(end)) < 0
            ? end
            : _tmp.end;
    } else {
        const _tm = {...schd};
        _tm.pre = pre;
        _tm.end = end;
        ordersMap.get(_key).push(_tm);
    }
};
/**
 * 根据订单号以及产品信息生成唯一key
 * @param {ScheduleResult} sch
 * @param {string} [split='@@@'] -分隔符
 * @return {string} -返回类似 order_code split product_code
 */
const mergeOrderPrdCodes = (sch, split = '@@@') => {
    const order_code = sch.order_code;
    const product_code = sch.product_info.code;
    return `${order_code}${split}${product_code}`;
};
/**
 * 根据出入的内容,返回拆分好的order_code,product_code
 * @param {string} key
 * @param {string} [split='@@@']
 * @returns {string[]}
 */
const parseOrderPrdCodes = (key, split = '@@@') => {
    return key.split(split);
};

function groupAndCount(data, keys) {
    const result = [];
    let count = 1;
    let previousItem = null;

    // 定义一个函数来比较两个对象是否在指定键上相等
    function itemsAreEqual(item1, item2, keys) {
        for (const key of keys) {
            if (item1[key] !== item2[key]) {
                return false;
            }
        }
        return true;
    }

    // 定义一个函数来检查对象的指定键是否为空
    function isNotEmpty(item, keys) {
        for (const key of keys) {
            if (item[key] === '' || item[key] === null || item[key] === undefined) {
                return false;
            }
        }
        return true;
    }

    for (const item of data) {
        // Check if the current item is the same as the previous one
        if (previousItem && isNotEmpty(item, keys) && itemsAreEqual(item, previousItem, keys)) {
        // If it's the same, increment the count
            count++;
        } else {
        // If it's different, push the count to the result array and reset the count
            if (previousItem && isNotEmpty(previousItem, keys)) {
                result.push(count);
            }
            count = 1; // Reset the count
        }
        previousItem = item; // Update the previous item
    }

    // Push the last count to the result array
    if (isNotEmpty(previousItem, keys)) {
        result.push(count);
    }

    return result;
}
const mergeProcessCells = (exp, _expStart, keys, ws, exHeader) => {
    const printSpans = groupAndCount(exp.routeList, keys);
    let spcount = 0;
    for (const sp of printSpans) {
        if (sp > 1) {
            const _spStart = _expStart + spcount;
            const _spEnd = _expStart + spcount + sp - 1;
            for (const pr of exHeader) {
                const _spCol = ws.getColumn(pr.excelColKey);
                ws.mergeCells(_spStart, _spCol.number, _spEnd, _spCol.number);
            }
        }
        spcount += sp;
    }
};
export default {
    writeProgressToExcel,
};
export {
    writeProgressToExcel,
};

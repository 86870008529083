<template>
    <el-row class="query-row" align="middle">
        <el-col :span="2">
            <el-input
                v-model="keywords.order_code"
                autocomplete="off"
                placeholder="订单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="3">
            <el-input
                v-model="keywords.product"
                autocomplete="off"
                placeholder="产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-input
                v-model="keywords.job_code"
                autocomplete="off"
                placeholder="任务单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.machine"
                autocomplete="off"
                clearable
                placeholder="选择机台">
                <el-option
                    v-for="(item) in machineList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value" />
            </el-select>
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isError"
                autocomplete="off"
                clearable
                placeholder="错误过滤">
                <el-option label="无错误" value="0" />
                <el-option label="有错误" value="1" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isFinished"
                autocomplete="off"
                clearable
                placeholder="完成状态">
                <el-option label="已完成" value="是" />
                <el-option label="未完成" value="否" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="3">
            <el-select
                v-model="keywords.dateOffState"
                autocomplete="off"
                clearable
                multiple
                collapse-tags
                collapse-tags-tooltip
                placeholder="延期状态">
                <el-option
                    v-for="(item) in shipmentCondtion()" :key="item.value"
                    :label="item.label" :value="item.value">
                    <div class="el-select-option-div">
                        <el-text
                            :style="{'background-color':item.color,
                                     'flex-basis':'45%',
                                     'text-align':'left'
                            }">
                            {{ item.label }}
                        </el-text>
                        <el-text
                            style="flex-basis:45%;text-align:left">
                            {{ item.ft }}
                        </el-text>
                    </div>
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="3">
            <div class="date-range">
                <el-date-picker
                    v-model="keywords.start"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="交期"
                    end-placeholder=""
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="default" />
            </div>
        </el-col>
        <el-col :span="3" />
    </el-row>
    <div class="batch-operate">
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="primary"
            @click="markedCalced(true)">
            标记参与计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="warning"
            @click="markedCalced(false)">
            标记不计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="danger"
            @click="deleteBatchJobs">
            批量删除
        </el-button>
    </div>
    <el-row class="table-container">
        <el-auto-resizer>
            <template #default="{ height, width }">
                <el-table-v2
                    v-model:sort-state="sortState"
                    :columns="columns"
                    :data="jobList"
                    :width="width-10"
                    :height="height-10"
                    expand-column-key="index"
                    row-key="serial_number"
                    fixed
                    :footer-height="40"
                    @column-sort="onSort">
                    <template #row="prop">
                        <Row v-bind="prop" />
                    </template>
                    <template #footer>
                        <el-row :gutter="1" align="middle" class="footer-summary-info">
                            <el-col :span="3">
                                <div>
                                    参与计算: {{ jobCalcQuantity() }} 万米
                                </div>
                            </el-col>
                            <el-col :span="2">
                                <div>
                                    参与计算: {{ uWbStore.jobList.filter(d=>d.isIncludedInCalculation).length }}
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-v2>
            </template>
        </el-auto-resizer>
    </el-row>
    <JobListEdit ref="refJobEdit" />
    <LockAddOpt
        :dialog-visible="showAddLock"
        :plan-date="uWbStore.g_plan_date"
        :job-info="jobInfo" @close="showAddLock=false" />
    <productOpt ref="refProductOpt" />
</template>
<script setup lang="jsx">
import SchdStore from '../stores/schdtabular.js';
import JobListOp from './job-list-op.vue';
import {notify} from '../assets/js/utils.js';
import {getErpPrd, getPrdInfo} from '../assets/js/erp2Schd.js';
// import {setPassive} from '../assets/js/setEventPassive.js';
import {h, computed, ref, Fragment, watch, defineProps} from 'vue';
import {
    ElCheckbox, ElText, ElIcon, ElTag, ElMessageBox,
    TableV2SortOrder, ElButton,
} from 'element-plus';
import {WarningFilled, Refresh, Edit, Plus} from '@element-plus/icons-vue';
import JobListEdit from './job-list-edit.vue';
import LockAddOpt from './lock-add-opt.vue';
import productOpt from './product-opt.vue';
import dayjs from 'dayjs';
const props = defineProps({
    isErpSync: {
        type: Boolean,
        default: false,
    },
});
const refProductOpt = ref(null);
const syncFromERP = async (row, model = 'syncErp') => {
    let prdData;
    if (model === 'syncErp') {
        const order = uWbStore.orderList.find((order) => {
            return order.order_code === row.order_code;
        });
        let prd = splitStringByLastColon(row.product_query);
        if (order) {
            prd = splitStringByLastColon(order.product_query);
        }
        const prdinfo = await getErpPrd(prd.p_code, prd.v_name);
        if (prdinfo.length === 0) {
            notify('error', '同步出现错误');
            return;
        }
        const _remote = await getPrdInfo(row.product);
        if (_remote.length > 0) {
            // 判断是否有同名的产品
            const sameNamePrd = _remote.find((prd) => {
                return prd.name === row.product;
            });
            if (sameNamePrd) {
                notify('error', `与产品编码:${sameNamePrd.code}的产品的名称冲突,注意ERP系统中的同名产品与自动排产中的编号是否一致`);
                return;
            }
        }
        // 由于历史原因或者先创建产品,这里可能会出现同名的产品信息
        prdinfo[0].code = `${prdinfo[0].code}:${prd.v_name}`;
        prdData = prdinfo[0];
    }
    if (model === 'edit') {
        const _remote = await getPrdInfo(row.product_info.code);
        if (_remote.length === 0) {
            notify('error', `编辑出现错误,没有找到编号:${row.product_info.code}的产品,请刷新页面再次尝试`);
            return;
        }
        prdData = _remote[0];
        if (props.isErpSync && prdData.name !== row.product) {
            prdData.name = row.product;
        }
    }
    if (model === 'add') {
        prdData = {'name': row.product};
    }
    const data = await refProductOpt.value.show({
        title: '产品同步添加',
        data: prdData,
        product_list: [],
    });
    if (data) {
        // 刷新
        row.product_info = data;
        row.product = row.product_info.name;
        await uWbStore.updateJob(row.serial_number, row);
        // 同步处理其他地方的相同的产品的错误信息
        // 处理订单信息的
        const orders = uWbStore.orderList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code);
        });
        orders.forEach(item => {
            uWbStore.updatePrdInfo(item, row.product_info);
        });
        const jobes = uWbStore.jobList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code);
        });
        jobes.forEach(job => {
            uWbStore.updatePrdInfo(job, row.product_info);
        });
        uWbStore.checkOrder(orders);
        uWbStore.checkJob(jobes);
    }
};
const splitStringByLastColon = (product) => {
    const parts = product.split(':');
    if (parts.length > 1) {
        const p_code = parts.slice(0, -1).join(':');
        const v_name = parts[parts.length - 1];
        return {
            p_code,
            v_name,
        };
    } else {
        return {
            p_code: product,
            v_name: '',
        };
    }
};
const isPrdNotFound = ({error}) => {
    return error.findIndex((err) => {
        return err.tag === 'product-not-found';
    }) > -1;
};
const uWbStore = SchdStore();
const jobListBase = uWbStore.jobList;
const jobList = computed(() => {
    jobListBase.forEach((item) => {
        // 将错误信息压缩为一个字符串,然后在生成tag的时候进行解压
        if (item.error.length > 0) {
            item.children = [{'detail': item.error.map((err) => err.info).join('@@')}];
        } else {
            item.children = null;
        }
    });
    let _jobList = jobListBase;
    const {order_code, product, job_code, machine, isError, isFinished, start} = keywords.value;
    const _orderCode = order_code.trim();
    const _product = product.trim();
    const _jobCode = job_code.trim();
    if (_orderCode || _product || _jobCode || machine || isError || isFinished) {
        _jobList = jobListBase.filter((item) => {
            const matchesOrderCode = !_orderCode || item.order_code.includes(_orderCode);
            const matchesProductName = !_product || item?.product?.includes(_product);
            const matchesJobCode = !_jobCode || item?.job_code?.includes(_jobCode);
            const matchesMachine = !machine || item?.machine?.includes(machine);
            const filterError = isError === ''
                ? true
                : (isError === '0' ? item.error.length === 0 : item.error.length > 0);
            const filterFinished = isFinished === '' ? true : item.is_finished === isFinished;
            // 只有当所有检查都通过时，才返回 true
            return matchesOrderCode && matchesProductName && matchesJobCode
             && matchesMachine && filterError && filterFinished;
        });
    }
    if (start && start.length === 2) {
        const startDate = dayjs(start[0]);
        const endDate = dayjs(start[1]);
        _jobList = _jobList.filter((job) => {
            const jobDate = dayjs(job.shipment_date);
            return jobDate.diff(startDate, 'days') >= 0 && endDate.diff(jobDate, 'days') >= 0;
        });
    }
    const {dateOffState} = keywords.value;
    if (dateOffState.length > 0) {
        _jobList = _jobList.filter((job) => {
            const days = dayjs(job.shipment_date).diff(uWbStore.g_plan_date, 'day');
            const c = getShipDateX(days).value;
            return dateOffState.indexOf(c) > -1;
        });
    }
    return _jobList;
});
// 监听计划日期的变化,如果交货期在计算范围之内,则自动将其设置为参与计算
watch(
    () => uWbStore.g_plan_date,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            jobListBase.forEach(isJobCalc);
        }
    },
);
// 监听计算天数的变化,如果交货期在计算范围之内,则自动将其设置为参与计算
watch(
    () => uWbStore.calculationDays,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            jobListBase.forEach(isJobCalc);
        }
    },
);

const jobCalcQuantity = () => {
    const _jobs = uWbStore.jobList.filter(d => d.isIncludedInCalculation);
    if (_jobs.length === 0) return 0;
    const res = _jobs.reduce((acc, cur) => acc + cur.quantity - cur.produced_quantity, 0);
    // 对res进行单位转换
    return (res / 10000).toFixed(1);
};
const isJobCalc = (job) => {
    const startDate = dayjs(uWbStore.g_plan_date).subtract(1, 'day');
    const endDate = dayjs(uWbStore.g_plan_date).add(uWbStore.calculationDays, 'day');
    const shipDate = dayjs(job.shipment_date);
    if (
        shipDate.isAfter(startDate, 'day')
        && shipDate.isBefore(endDate, 'day')
        && job.error.length === 0
        && job.is_finished !== '是'
    ) {
        job.isIncludedInCalculation = true;
    } else {
        job.isIncludedInCalculation = false;
    }
};
const SelectionCell = ({
    value,
    intermediate = false,
    onChange,
}) => {
    return h(ElCheckbox, {modelValue: value, indeterminate: intermediate, onChange});
};
const indexCell = ({isError, index}) => {
    const icon = isError
        ? () => h(ElIcon, null, {default: () => h(WarningFilled)})
        : null;
    return h(
        ElText,
        {
            class: 'mx-1',
            type: isError ? 'danger' : '',
        },
        {default: () => [index, icon ? icon() : null]},
    );
};
const errorTags = (errorStringList) => {
    const errors = errorStringList.split('@@');
    return h(Fragment, null, errors.map((error) => {
        return h(ElTag, {
            type: 'danger',
            size: 'large',
        }, {default: () => error});
    }));
};
const switchState = (rowData, val) => {
    rowData.isIncludedInCalculation = val;
};
const switchFinishedState = (rowData, val) => {
    setTimeout(() => {
        if (val === '是') {
            rowData.isIncludedInCalculation = false;
        }
        rowData.is_finished = val;
    }, 100);
};
const markedCalced = (val) => {
    for (const job of jobList.value) {
        if (job.checked && job.is_finished !== '是' && job.error.length === 0) {
            job.isIncludedInCalculation = val;
        }
    }
};
const Row = (obj) => {
    if (obj.rowData.detail) {
        return errorTags(obj.rowData.detail);
    }
    return obj.cells;
};
// 不继承父节点的style属性
Row.inheritAttrs = false;
const shipmentCondtion = () => {
    return [
        {
            'label': '严重延误',
            range: [-Infinity, -10],
            value: 'severe-delay',
            color: '#ff4d4d',
            'ft': '延10天及以上',
        },
        {
            'label': '中等延误',
            range: [-9, -4],
            value: 'moderate-delay',
            color: '#ffbb33',
            'ft': '延4至10天',
        },
        {
            'label': '延误',
            range: [-3, -1],
            value: 'mild-delay',
            color: '#ffdd57',
            'ft': '延1至3天',
        },
        {
            'label': '正常',
            range: [0, uWbStore.calculationDays - 1],
            value: 'on-time',
            color: '#70e970',
            'ft': '计划天数内',
        },
        {
            'label': '未来',
            range: [uWbStore.calculationDays, Infinity],
            value: 'infuture',
            color: '#33b5e5',
            'ft': '超计算天数',
        },
    ];
};
const getShipDateX = (days) => {
    for (const condtion of shipmentCondtion()) {
        if (condtion.range[0] <= days && days <= condtion.range[1]) {
            return condtion;
        }
    }
    throw new Error(`${days} is not in any range`);
};
const delayClassState = ({rowData}) => {
    const startDate = dayjs(uWbStore.g_plan_date);
    if (!startDate.isValid()) return '';
    const rShipDate = dayjs(rowData.shipment_date);
    if (!startDate.isValid()) return '';
    const delayDays = rShipDate.diff(startDate, 'day');
    // 严重延误的任务
    return getShipDateX(delayDays).value;
};
const offsetDays = ({rowData}) => {
    // 根据交货日期与计划日期与计算天数进行比对,来计算偏离天数
    const startDate = dayjs(uWbStore.g_plan_date);
    if (!startDate.isValid()) return '';
    const rShipDate = dayjs(rowData.shipment_date);
    if (!startDate.isValid()) return '';
    const diffDays = rShipDate.diff(startDate, 'day');
    if (diffDays >= 0 && diffDays < uWbStore.calculationDays) return '';
    if (diffDays < 0) return diffDays;
    return `+${diffDays - uWbStore.calculationDays + 1}`;
};
// 操作列组件逻辑
const refJobEdit = ref(null);
const deleteJob = ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    ElMessageBox.confirm(
        `删除本行数据？${job.job_code}`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            uWbStore.deleteJob(job.serial_number);
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
const editJob = async ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    const res = await refJobEdit.value.show({
        title: '任务修改',
        data: Object.assign({}, job),
    });
    if (res) {
        // 更新数据
        uWbStore.updateJob(serial_number, res);
    }
};
const jobInfo = ref(null);
const showAddLock = ref(false);
const addLock = ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    jobInfo.value = {...job};
    showAddLock.value = true;
};
const opCell = (row) => {
    return h(JobListOp,
        {
            key: `operate-${row.serial_number}`,
            row,
            onEdit(row) {
                editJob(row);
            },
            onDel(row) {
                deleteJob(row);
            },
            onLock(row) {
                addLock(row);
            },
        });
};
const deleteBatchJobs = () => {
    // 删除选中的所有任务信息
    const toDelJobs = jobList.value.filter((item) => item.checked);
    ElMessageBox.confirm(
        `删除${toDelJobs.length}行数据？`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            toDelJobs.forEach((item) => {
                uWbStore.deleteJob(item.serial_number);
            });
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// 排序功能
const sortState = ref({
    'shipment_date': TableV2SortOrder.ASC,
});

const onSort = ({key, order}) => {
    sortState.value[key] = order;
    const _direction = sortState.value[key] === TableV2SortOrder.ASC ? 1 : -1;
    uWbStore.jobList.sort((a, b) => {
        if (a[key] !== b[key]) {
            return (a[key] > b[key] ? 1 : -1) * _direction;
        }
        return 0;
    });
};

// 查询功能
const keywords = ref({
    'order_code': '',
    'product': '',
    'machine': '',
    'job_code': '',
    'isError': '',
    'isFinished': '否',
    'start': [],
    'dateOffState': [],
});
const machineList = computed(() => {
    const maclist = [...new Set(uWbStore.jobList.map((item) => {
        return item['machine'];
    }))];
    const _mac = [];
    maclist.forEach((m) => {
        if (m) {
            _mac.push({
                label: m,
                value: m,
            });
        }
    });
    _mac.sort((a, b) => a.label.localeCompare(b.label, 'zh-Hans', {numeric: true}));
    _mac.push({
        label: '全部',
        value: '',
    });
    return _mac;
});
const columns = [
    {
        key: 'selection',
        width: 40,
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            const onChange = (value) => (rowData.checked = value);
            return SelectionCell({value: rowData.checked, onChange});
        },
        headerCellRenderer: () => {
            const onChange = (value) => {
                jobList.value.forEach((item) => { item.checked = value; return item; });
            };
            const allSelected = jobList.value.every((row) => row.checked);
            const containsChecked = jobList.value.some((row) => row.checked);
            return SelectionCell({
                value: allSelected,
                intermediate: containsChecked && !allSelected,
                onChange,
            });
        },
    },
    {
        width: 75,
        title: '序号',
        key: 'index',
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData, rowIndex}) => {
            return indexCell({isError: rowData.error.length > 0, index: rowIndex + 1});
        },
    },
    {
        key: 'order_code',
        title: '订单号',
        dataKey: 'order_code',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_code',
        title: '任务号',
        dataKey: 'job_code',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'product',
        title: '产品',
        dataKey: 'product',
        width: 220,
        class: 'table-col product col-left',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            if (isPrdNotFound(rowData)) {
                return <>
                    <el-tooltip
                        content={props.isErpSync ? '同步产品' : '添加产品'} placement="left"
                        show-after={500}
                    >
                        <el-button
                            link
                            type="primary"
                            size="default"
                            onClick={() => {
                                if (props.isErpSync) {
                                    syncFromERP(rowData);
                                } else {
                                    syncFromERP(rowData, 'add');
                                }
                            }}>
                            {props.isErpSync ? <el-icon><Refresh /></el-icon> : <el-icon><Plus /></el-icon>}
                        </el-button>
                    </el-tooltip>
                    <div class="el-table-v2__cell-text"
                        style="white-space: pre" title={rowData.product}>{ rowData.product }</div>
                </>;
            }
            return <>
                <el-tooltip
                    content="修改产品" placement="left"
                    show-after={500}
                >
                    <el-button
                        link
                        type="primary"
                        size="default"
                        onClick={() => { syncFromERP(rowData, 'edit'); }}>
                        <el-icon><Edit /></el-icon>
                    </el-button>
                </el-tooltip>
                <div class="el-table-v2__cell-text"
                    style="white-space: pre" title={rowData.product}>{ rowData.product }</div>
                {props.isErpSync && rowData?.product_info && rowData.product !== rowData?.product_info?.name
                    ? <el-tooltip
                        content="ERP导入产品名称与本地不一致,请修改" placement="top"
                        show-after={500}
                    >
                        <el-icon color='#f56c6c'><QuestionFilled /></el-icon>
                    </el-tooltip>
                    : ''
                }
            </>;
        },
    },
    {
        key: 'quantity',
        title: '数量',
        dataKey: 'quantity',
        width: 100,
        class: 'table-col col-right',
        headerClass: 'job-table-header',
    },
    {
        key: 'produced_quantity',
        title: '已生产',
        dataKey: 'produced_quantity',
        width: 100,
        class: 'table-col col-right',
        headerClass: 'job-table-header',
    },
    {
        key: 'process_name',
        title: '工序',
        dataKey: 'process_name',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'is_finished',
        title: '完成',
        dataKey: 'is_finished',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            if (!rowData) return null;
            return <el-switch
                modelValue={rowData.is_finished}
                inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                active-value="否"
                active-text="否"
                inactive-text="是"
                inactive-value="是"
                onChange={(val) => {
                    switchFinishedState(rowData, val);
                }} />;
        },
    },
    {
        key: 'isIncludedInCalculation',
        title: '参与计算',
        dataKey: 'isIncludedInCalculation',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            if (!rowData) return null;
            return <el-switch
                modelValue={rowData.isIncludedInCalculation}
                inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                active-text="是"
                inactive-text="否"
                active-value={true}
                inactive-value={false}
                disabled={rowData.is_finished === '是' || rowData.error.length > 0}
                beforeChange={() => {
                    const _orgVal = rowData.isIncludedInCalculation;
                    if (_orgVal) return true;
                    if (!dayjs(uWbStore.g_plan_date).isValid()) return true;
                    return true;
                }}
                onChange={(val) => {
                    switchState(rowData, val);
                }} />;
        },
        // cellRenderer: ({rowData}) => {
        //     return <SwitchCalculation
        //         key={`isIncl-${rowData.serial_number}`}
        //         state={
        //             rowData.is_finished === '是' || rowData.error.length > 0
        //                 ? false
        //                 : rowData.isIncludedInCalculation
        //         }
        //         serialNumber={rowData.serial_number}
        //         disabled={rowData.is_finished === '是' || rowData.error.length > 0}
        //         onSwitch={switchState}
        //     />;
        // },
    },
    {
        key: 'shipment_date',
        title: '计划交期',
        dataKey: 'shipment_date',
        width: 135,
        class: 'table-col shipment-date',
        headerClass: 'job-table-header',
        sortable: true,
        cellRenderer: ({rowData}) => {
            const offset = offsetDays({rowData});
            const cl = delayClassState({rowData});
            return (
                <div style={
                    {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: offset ? 'space-between' : 'flex-start',
                    }
                }
                class={cl}
                >
                    <el-text style={{flex: offset ? '0 0 auto' : '1 1 auto', textAlign: 'left'}}>
                        {rowData.shipment_date}
                    </el-text>
                    {offset && (
                        <>
                            <el-divider direction="vertical"
                                color='black'
                                style={{
                                    margin: '0 10px',
                                    'border-left-color': 'black', // 设置分隔线颜色为黑色
                                    height: '24px', // 增加分隔线高度
                                }}/>
                            <el-text style={{flex: '0 0 auto', textAlign: 'right'}}>{offset}</el-text>
                        </>
                    )}
                </div>
            );
        },
    },
    {
        key: 'machine',
        title: '已排产机台',
        dataKey: 'machine',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_run',
        title: '开始时间',
        dataKey: 'job_run',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_end',
        title: '完成时间',
        dataKey: 'job_end',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'remark',
        title: '备注',
        dataKey: 'remark',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
        // cellRenderer: ({rowData}) => {
        //     return <>{rowData.serial_number}</>;
        // },
    },
    {
        key: 'op',
        width: 100,
        title: '操作',
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            return opCell(rowData);
        },
    },
];
</script>
<style>
:root {
    --severe-delay-color: #ff4d4d;
    --moderate-delay-color: #ffbb33;
    --mild-delay-color: #ffdd57;
    --on-time-color: #70e970;
    --infuture-color: #33b5e5;
}
.severe-delay {
    color:var(--severe-delay-color);
}
.moderate-delay {
    color:var(--moderate-delay-color);
}
.mild-delay {
    color:var(--mild-delay-color);
}
.on-time {
    color:var(--on-time-color);
}
.infuture {
    color: var(--infuture-color)
}
.shipment-date:has(.severe-delay) {
    background-color: var(--severe-delay-color);
    border:1px solid black;
}
.shipment-date:has(.moderate-delay) {
    background-color:var(--moderate-delay-color);
    border:1px solid black;
}
.shipment-date:has(.mild-delay) {
    background-color: var(--mild-delay-color);
    border:1px solid black;
}
.shipment-date:has(.on-time) {
    background-color: var(--on-time-color);
    border:1px solid black;
}
.shipment-date:has(.infuture) {
    background-color: var(--infuture-color);
    border:1px solid black;
}
.el-select-option-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-summary-info {
    height:100%;
}
.footer-summary-info>.el-col {
    border-radius: 4px;
    height:100%;
}
.footer-summary-info>.el-col>div {
    border-radius: 10px;
    height:100%;
    font-size:1.2rem;
    background-color: #67c23a;
    align-items: center;
    display: flex;
    justify-content: center;
    color:whitesmoke;
}
</style>
<style scoped>
.table-col {
    border-left: 1px solid #ebeef5;
}
.batch-operate {
    position: absolute;
    top:20px;
    right:25px;
}
.product {
    font-size:0.8rem;
    text-align: left;
    text-wrap:nowrap;
}
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 50px);
}
.date-range {
    width:100%;
}
:deep(.date-range .el-input__wrapper) {
    width: 85% !important;
}
</style>

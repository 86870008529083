<template>
    <el-button
        v-loading.fullscreen.lock="importLoading"
        element-loading-text="正在导入ERP任务数据"
        type="primary" @click="importErpTask">
        导入ERP任务
    </el-button>
    <el-button
        v-loading.fullscreen.lock="exportLoading"
        element-loading-text="正在向ERP发送排产数据"
        type="danger" @click="exportSchToERP">
        发送排产结果
    </el-button>
</template>
<script>
import SchdStore from '../stores/schdtabular.js';
import {notify} from '../assets/js/utils.js';
import {getErpTask, sendSchToERP} from '../assets/js/erp2Schd.js';
export default {
    name: 'ErpTaskInfo',
    setup() {
        const uWbStore = SchdStore();
        return {uWbStore};
    },
    data() {
        return {
            importLoading: false,
            exportLoading: false,
        };
    },
    methods: {
        async importErpTask() {
            try {
                this.importLoading = true;
                const taskInfo = await getErpTask();
                this.uWbStore.reset();
                await this.uWbStore.initData({
                    orderList: taskInfo.eOrders,
                    jobList: taskInfo.eJobs,
                    schdList: taskInfo.eSchs,
                });
            } catch (error) {
                notify(error);
                console.error(error);
            } finally {
                this.importLoading = false;
            }
        },
        async exportSchToERP() {
            const _schdList = this.uWbStore.schdList.filter((sch) => sch.error.length === 0);
            const _jobList = this.uWbStore.jobList.filter((sch) => sch.error.length === 0);
            const estimateSchdList = this.uWbStore.estimateSchdList;
            try {
                this.exportLoading = true;
                const result = await sendSchToERP({
                    'jobList': _jobList,
                    'schdList': _schdList,
                    estimateSchdList,
                    'plan_date': this.uWbStore.g_plan_date,
                });
                if (result.status === 1) {
                    notify('success', '同步成功', 3000);
                }
            } catch (error) {
                console.error(error);
                notify(error);
            } finally {
                this.exportLoading = false;
            }
        },
    },
};
</script>

import {defineStore} from 'pinia';
import {notify} from '../assets/js/utils.js';
import {stringify} from '../assets/js/json.js';
import {autoTabluar, ErrorTags} from '../assets/js/schd.js';
import axios from 'axios';
import dayjs from 'dayjs';
/**
 * @import {OrderResult,JobResult,ScheduleResult,LockInfo,calcPostData,Job} from '../assets/js/schdTypes.js';
 * @import {machine} from '../assets/js/schdTypes.js';
 */
const SchdStore = defineStore({
    id: 'SchdStore',
    state: () => ({
        /** @type {OrderResult[]} */
        orderList: [],
        /** @type {JobResult[]} */
        jobList: [],
        /** @type {LockInfo[]} */
        LockInfoList: [],
        /** @type {ScheduleResult[]} */
        schdList: [],
        /** @type {ScheduleResult[]} */
        schdHistory: [], // 历史排产
        /** @type {ScheduleResult[]} */
        estimateSchdList: [],
        g_plan_date: null,
        currentTaskId: null,
        taskStart: null,
        taskRunMaxSec: 30,
        calculationDays: 7,
    }),
    getters: {
        // 订单编号统计
        orderCodesCount: (state) => {
            return state.orderList.reduce((obj, item) => {
                if (obj[item.order_code]) {
                    obj[item.order_code]++;
                } else {
                    obj[item.order_code] = 1;
                }
                return obj;
            }, {});
        },
        // 已排产的汇总信息
        schedSummary() {
            const summary = {};
            // job-code,process-name:summary
            this.schdList.forEach((item) => {
                const groupkey = [item.job_code, item.product_info?.id || '', item.process_name].join('-');
                const schdDate = dayjs(item.date, 'YYYY-MM-DD');
                const itemPre = dayjs(item.pre, 'HH:mm');
                const itemEnd = dayjs(item.end, 'HH:mm');
                const itemDateWithPre = schdDate.hour(itemPre.hour()).minute(itemPre.minute());
                const itemDateWithEnd = schdDate.hour(itemEnd.hour()).minute(itemEnd.minute());
                if (!summary[groupkey]) {
                    summary[groupkey] = {
                        min: itemDateWithPre,
                        max: itemDateWithEnd,
                        machine: item.machine,
                    };
                } else {
                    if (itemDateWithPre.isBefore(summary[groupkey].min)) {
                        summary[groupkey].min = itemDateWithPre;
                    }
                    if (itemDateWithEnd.isAfter(summary[groupkey].max)) {
                        summary[groupkey].max = itemDateWithEnd;
                    }
                }
            });
            for (const groupkey in summary) {
                summary[groupkey].min = summary[groupkey].min.format('YYYY-MM-DD HH:mm');
                summary[groupkey].max = summary[groupkey].max.format('YYYY-MM-DD HH:mm');
            }
            return summary;
        },
    },
    actions: {
        reset() {
            this.orderList.length = 0;
            this.jobList.length = 0;
            this.schdList.length = 0;
            this.schdHistory.length = 0;
        },
        isTaskRunning() {
            if (!dayjs(this.taskStart).isValid()) return false;
            const now = dayjs();
            return (now.diff(dayjs(this.taskStart), 'second') < this.taskRunMaxSec);
        },
        async loadExcel(file) {
            try {
                const _excelData = await autoTabluar.parseExcelFile(file);
                this.reset();
                await this.initData(_excelData);
                this.resetRunningTask();
            } catch (error) {
                notify(`Excel文档解析出现错误!${error.message}`);
                throw error;
            }
        },
        /**
         * @typedef AutoSchdData
         * @property {Array} orderList
         * @property {Array} jobList
         * @property {Array} schdList
         */
        /**
         *
         * @param {AutoSchdData} data
         */
        async initData(data) {
            // 加载order-list
            await this.addOrder(data.orderList);
            await this.addJobs(data.jobList, true);
            await this.addSchdHistory(data.schdList);
            await this.syncProduct(this.jobList);
            this.syncScheduleSummary(this.jobList);
            this.checkJob(this.jobList);
        },
        deleteOrder(serial_number) {
            const index = this.orderList.findIndex((item) => {
                return item.serial_number === serial_number;
            });
            if (index === -1) throw new Error(`没有找到序号为${serial_number}的订单信息`);
            // 同步删除job
            const order_code = this.orderList[index].order_code;
            const _jbid = this.jobList.filter((item) => {
                return item.order_code === order_code;
            }).map((item) => {
                return item.serial_number;
            });
            _jbid.forEach((item) => {
                this.deleteJob(item);
            });
            this.orderList.splice(index, 1);
        },
        deleteJob(serial_number) {
            // 同步删除已排产数据,和锁定数据
            const index = this.jobList.findIndex((item) => {
                return item.serial_number === serial_number;
            });
            if (index === -1) throw new Error(`没有找到序号为${serial_number}的任务信息`);
            const job_code = this.jobList[index].job_code;
            // 删除已排产数据
            this.schdList.filter((item) => {
                return item.job_code === job_code;
            }).forEach((item) => {
                this.deleteSchd(item.serial_number);
            });
            this.schdHistory.filter((item) => {
                return item.job_code === job_code;
            }).forEach((item) => {
                this.deleteSchdHistory(item.serial_number);
            });
            this.jobList.splice(index, 1);
        },
        deleteSchd(serial_number) {
            const index = this.schdList.findIndex(item => {
                return item.serial_number === serial_number;
            });
            if (index === -1) {
                console.error('未找到对应的排产单');
                return;
            }
            // 重新更新对应job中的summary
            const job_code = this.schdList[index].job_code;
            this.schdList.splice(index, 1);
            const toSyncJobs = this.jobList.filter((item) => {
                return job_code === item.job_code;
            });
            this.syncScheduleSummary(toSyncJobs);
        },
        deleteSchdHistory(serial_number) {
            const index = this.schdHistory.findIndex(item => {
                return item.serial_number === serial_number;
            });
            if (index === -1) {
                console.error('未找到对应的排产单');
                return;
            }
            // 重新更新对应job中的summary
            const job_code = this.schdHistory[index].job_code;
            this.schdHistory.splice(index, 1);
            const toSyncJobs = this.jobList.filter((item) => {
                return job_code === item.job_code;
            });
            this.syncScheduleSummary(toSyncJobs);
        },
        /**
         *
         * @param {string} serial_number
         * @param {Object} data
         */
        async updateOrder(serial_number, data) {
            const _order = this.orderList.find((item) => {
                return item.serial_number === serial_number;
            });
            if (!_order) throw new Error(`没有找到序号为${serial_number}的订单信息`);
            Object.keys(data).forEach((key) => {
                _order[key] = data[key];
            });
            await this.syncProduct([_order]);
            for (const order of [_order]) {
                if (order.product_info) {
                    order.product_id = order.product_info.id;
                }
            }
            this.checkOrder([_order]);
        },
        /**
         *
         * @param {string} serial_number
         * @param {Object} data
         */
        async updateJob(serial_number, data) {
            const _order = this.jobList.find((item) => {
                return item.serial_number === serial_number;
            });
            if (!_order) throw new Error(`没有找到序号为${serial_number}的任务信息`);
            Object.keys(data).forEach((key) => {
                _order[key] = data[key];
            });
            await this.syncProduct([_order]);
            this.checkJob([_order]);
        },
        async addCalcedSchd(calcResult) {
            // 对传递的结果进行展开
            const _jobList = [];
            /** @type {ScheduleResult[]} */
            const _schdList = [];
            const machie = await this.fetchMachines();
            /** @type {array} */
            const macList = machie.data;
            calcResult.forEach((item) => {
                const job = this.jobList.find((job) => {
                    return job.job_code === item.job_code;
                });
                if (job) {
                    item.days.forEach((day) => {
                        const _schd = this.getEmpyRow('shcd');
                        const _date = dayjs(day.date).format('YYYY-MM-DD');
                        const _end = dayjs(day.end, 'HH:mm').format('HH:mm');
                        const _pre = dayjs(day.pre, 'HH:mm').format('HH:mm');
                        const _run = dayjs(day.run, 'HH:mm').format('HH:mm');
                        _schd.job_code = job.job_code;
                        _schd.quantity = job.quantity;
                        _schd.product = job.product;
                        _schd.order_code = job.order_code;
                        const mac = macList.find((mac) => {
                            return mac.code === item.machine_code;
                        });
                        _schd.machine = mac.name;
                        _schd.end = _end;
                        _schd.pre = _pre;
                        _schd.run = _run;
                        _schd.date = _date;
                        _schd.process_name = job.process_name;
                        _schd.process_cate_name = job.process_cate_name;
                        _schd.is_locked = this.calcedSchdIsLocked(_schd);
                        // 判断该排产是否已锁定
                        _schdList.push(_schd);
                    });
                    _jobList.push(job);
                }
            });
            if (_schdList.length > 0) {
                this.schdList.length = 0;
                // 对结果进行排序按照机台,日期+pre
                _schdList.sort((a, b) => {
                    const _a = a.machine + a.date + a.pre;
                    const _b = b.machine + b.date + b.pre;
                    return _a > _b ? 1 : -1;
                });
                await this.addSchd(_schdList);
                this.syncScheduleSummary(_jobList);
            }
        },
        /**
         *
         * @param {ScheduleResult} schd
         * @returns {string} - 是|否
         */
        calcedSchdIsLocked(schd) {
            const _index = this.LockInfoList.findIndex(lock => {
                if (lock.job_code !== schd.job_code) return false;
                return lock.schedule.find(day => dayjs(day.date).diff(dayjs(schd.date), 'day') === 0);
            });
            return _index > -1 ? '是' : '否';
        },
        updatePrdInfo(item, info) {
            item.product_info = info;
        },
        checkJob(items) {
            items.forEach((item) => {
                // 订单号是否存在校验
                this.updateJobOrderCodeErrorTags(item);
                //  产品信息校验
                this.updatePrdErrorTags(item);
                // 工序校验
                this.updateJobProcessErrorTags(item);
                // job与订单产品是否相符合校验
                this.updateJobOrderPrdErrorTags(item);
                this.updateJobDateErrorTags(item);
            });
        },
        checkSchd(items) {
            items.forEach((item) => {
                // 机台检验
                this.updateMacErrorTags(item);
                // 产品检验
                this.updatePrdErrorTags(item);
                // jobcode检验
                this.updateSchdJobErrorTags(item);
            });
        },
        getJobSchedInfo(groupkey) {
            const summary = {count: 0};
            const _fn = (arr) => {
                for (const item of arr) {
                    const _g = [item.job_code, item.product_info?.id || '', item.process_name].join('-');
                    if (groupkey !== _g) continue;
                    let itemPre = dayjs(`${item.date} ${item.pre}`, 'YYYY-MM-DD HH:mm');
                    if (itemPre.isValid() === false) itemPre = dayjs(item.date);
                    let itemEnd = dayjs(`${item.date} ${item.end}`, 'YYYY-MM-DD HH:mm');
                    if (itemEnd.isValid() === false) itemEnd = dayjs(item.date);
                    if (summary.count > 0) {
                        summary.min = itemPre.isBefore(summary.min) ? itemPre : summary.min;
                        summary.max = itemEnd.isAfter(summary.max) ? itemEnd : summary.max;
                    } else {
                        summary.min = itemPre;
                        summary.max = itemEnd;
                    }
                    summary.machine = item.machine;
                    summary.count += 1;
                }
            };
            _fn(this.schdHistory);
            if (summary.count > 0) {
                summary.min = summary.min.format('YYYY-MM-DD HH:mm');
                summary.max = summary.max.format('YYYY-MM-DD HH:mm');
                return summary;
            }
            _fn(this.schdList);
            if (summary.count > 0) {
                summary.min = summary.min.format('YYYY-MM-DD HH:mm');
                summary.max = summary.max.format('YYYY-MM-DD HH:mm');
                return summary;
            }
            return null;
        },
        syncScheduleSummary(jobItems) {
            jobItems.forEach(item => {
                const groupkey = [item.job_code, item.product_info?.id || '', item.process_name].join('-');
                const _summaryItem = this.getJobSchedInfo(groupkey);
                if (_summaryItem) {
                    item.machine = _summaryItem.machine;
                    item.job_run = _summaryItem.min;
                    item.job_end = _summaryItem.max;
                } else {
                    item.machine = '';
                    item.job_run = '';
                    item.job_end = '';
                }
            });
        },
        addItems(items, model, poper) {
            // const model = `${typed}Model`;
            // const handleList = `${typed}List`;
            const _serial_numbers = items.map((item) => {
                if (!Object.hasOwn(item, 'serial_number') || item.serial_number === '') {
                    item.serial_number = autoTabluar.uniqueId(autoTabluar[model].dataDefined.name);
                }
                this[poper].push(item);
                return item.serial_number;
            });
            const addItems = _serial_numbers.map((sn) => {
                return this[poper].find((item) => {
                    return item.serial_number === sn;
                });
            });
            return addItems;
        },
        async addOrder(items) {
            /** @type {OrderResult[]} */
            const addItems = this.addItems(items, 'orderModel', 'orderList');
            await this.syncProduct(addItems);
            // 处理同步产成品之后,将product_id进行赋值
            for (const order of addItems) {
                if (order.product_info) {
                    order.product_id = order.product_info.id;
                }
            }
            this.checkOrder(addItems);
        },
        async addJobs(jobs, isLoadByExcelOrERPs = false) {
            // isLoadByExcelOrERPs:判断是否为excel导入或者erp导入
            const addItems = this.addItems(jobs, 'jobModel', 'jobList');
            addItems.forEach((item) => {
                // 判断是否为完成状态
                if (item.is_finished !== '是') {
                    const quantity = Number(item.quantity);
                    const produced_quantity = Number(item.produced_quantity);
                    if (!isNaN(quantity) && !isNaN(produced_quantity)
                        && parseInt(quantity) * 0.9 <= parseInt(produced_quantity)) {
                        item.is_finished = '是';
                    }
                }
                if (item.is_finished === '是') {
                    item.isIncludedInCalculation = false;
                }
            });
            if (!isLoadByExcelOrERPs) {
                await this.syncProduct(addItems);
                this.syncScheduleSummary(addItems);
                this.checkJob(addItems);
            }
        },
        async addSchd(schds) {
            const addItems = this.addItems(schds, 'schdModel', 'schdList');
            await this.syncProduct(addItems);
            await this.syncMachineInfo(addItems);
            this.checkSchd(addItems);
        },
        async addSchdHistory(schds) {
            const addItems = this.addItems(schds, 'schdHistoryModel', 'schdHistory');
            await this.syncProduct(addItems);
            await this.syncMachineInfo(addItems);
            this.checkSchd(addItems);
        },
        filterOrderByCode(order_code) {
            return this.orderList.filter((order) => {
                return order.order_code === order_code;
            });
        },
        isOrderFoundInJobs(order_code) {
            return this.jobList.find((item) => {
                return item.order_code === order_code;
            });
        },
        getEmpyRow(name) {
            switch (name) {
            case 'orderList':
            case 'order':
                return autoTabluar.mkOrderTpl();
            case 'job':
            case 'jobList':
                return autoTabluar.mkJobTpl();
            case 'shcd':
            case 'schdList':
                return autoTabluar.mkSchdTpl();
            }
        },
        /**
         *
         * @param {Array[Object]} items
         */
        checkOrder(items) {
            // 订单编号重复
            this.updateOrderCodeErrorTags();
            items.forEach(item => {
                // 日期校验
                this.updateOrderDateErrorTags(item);
                // 产品信息校验
                this.updatePrdErrorTags(item);
            });
        },
        async fetchProducts(args) {
            const url = `/api/products/`;
            const params = {};
            if (args.products) {
                params.q = args.products;
            }
            try {
                const res = await axios.get(url, {params});
                if (res.data.error) {
                    throw new Error(res.data.error);
                }
                return res.data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // 处理 Axios 错误
                    console.error('Axios Error:', error.message);
                }
                throw error;
            }
        },
        updateOrderCodeErrorTags() {
            this.orderList.forEach((orderData) => {
                this.updateErrorTags(orderData.error,
                    ErrorTags['order-code-repeat'],
                    this.orderCodesCount[orderData.order_code] > 1);
            });
        },
        updateOrderDateErrorTags(orderData) {
            // earliest,latest,shipment_date都不能为空
            this.updateErrorTags(orderData.error,
                ErrorTags['order-date-require'], !orderData.shipment_date,
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['shipment-format'],
                !dayjs(orderData.shipment_date,
                    autoTabluar.dateFmt('orderModel', 'shipment_date')).isValid(),
            );
        },
        updateJobOrderCodeErrorTags(jobData) {
            this.updateErrorTags(jobData.error,
                ErrorTags['order-code-not-found'],
                this.filterOrderByCode(jobData.order_code).length === 0);
        },
        updateJobOrderPrdErrorTags(jobItem) {
            // 与订单产品进行比较
            const _prd = this.filterOrderByCode(jobItem.order_code).filter((order) => {
                return (jobItem?.product_info?.code || 1) === (order?.product_info?.code || 2);
            });
            this.updateErrorTags(jobItem.error,
                ErrorTags['job-prd-not-equel-order'],
                _prd.length === 0);
        },
        updateJobProcessErrorTags(jobData) {
            if (jobData.product_info) {
                this.removeError(jobData.error, 'job-process-not-found');
                const process = jobData.product_info?.craft.find((craft) => {
                    return craft.name === jobData.process_name;
                });
                this.updateErrorTags(jobData.error,
                    ErrorTags['job-process-not-found'],
                    !process,
                );
            }
        },
        updateJobDateErrorTags(jobData) {
            // earliest,latest,shipment_date都不能为空
            this.updateErrorTags(jobData.error,
                ErrorTags['job-date-require'],
                !jobData.shipment_date,
            );
            this.updateErrorTags(jobData.error,
                ErrorTags['shipment-date-format'],
                !dayjs(jobData.shipment_date, autoTabluar.dateFmt('jobModel', 'shipment_date')).isValid(),
            );
        },
        updatePrdErrorTags(item) {
            this.updateErrorTags(item.error, ErrorTags['product-not-found'],
                item.product_info === null);

            if (item.product_info !== null) {
                this.updateErrorTags(item.error, ErrorTags['product-not-cfg'],
                    item.product_info.state === '未配置');
            }
        },
        updateSchdJobErrorTags(item) {
            // 判断job_code 是否在joblist中
            const err = this.jobList.findIndex(job => job.job_code === item.job_code) === -1;
            this.updateErrorTags(item.error, ErrorTags['job-not-found'],
                err);
        },
        async syncProduct(localItems) {
            // 增加一个product查询字段
            for (const item of localItems) {
                if (item?.pro_code) {
                    item.product_query = item.pro_code;
                } else {
                    item.product_query = item.product;
                }
            }
            // 同步远程产品信息到本土产品信息,重命名product_code
            const localProducts = [...new Set(localItems.map(item => item.product_query))];
            if (localProducts.length === 0) return;
            const fetchProductsPromise = [];
            // const gsize = 25;
            const serveMaxLength = 3000;
            const prdGroups = localProducts.reduce((acc, cur, index) => {
                if (index === 0) {
                    acc.push([cur]);
                    return acc;
                }
                const _textSize = encodeURI(cur).length
              + encodeURI(acc[acc.length - 1].join(',')).length;
                if (_textSize >= serveMaxLength) {
                    acc.push([cur]);
                } else {
                    acc[acc.length - 1].push(cur);
                }
                return acc;
            }, []);
            prdGroups.forEach((prds) => {
                fetchProductsPromise.push(
                    this.fetchProducts({'products': prds.join(',')}));
            });
            // for (let i = 0; i < localProducts.length; i += gsize) {
            //     fetchProductsPromise.push(
            //         this.fetchProducts({'products': localProducts.slice(i, i + gsize).join(',')}));
            // }
            await Promise.all(fetchProductsPromise)
                .then((products) => {
                    const remoteProducts = products.reduce((acc, cur) => {
                        acc.push(...cur.data);
                        return acc;
                    }, []);
                    localItems.forEach(item => {
                        const remoteProduct = remoteProducts.filter((prd) => {
                            return (prd.code === item.product_query || prd.name === item.product_query);
                        });
                        if (remoteProduct.length > 0) {
                            const code = remoteProduct[0].code;
                            const id = remoteProduct[0].id;
                            const name = remoteProduct[0].name;
                            const stru = remoteProduct[0]?.stru || '';
                            const state = remoteProduct[0]?.data?.['工艺'].length > 0 ? '已配置' : '未配置';
                            const craft = remoteProduct[0]?.data?.['工艺'];
                            item.product_info = {code, id, state, name, craft, stru};
                            this.syncProcessCateName(item);
                        } else {
                            item.product_info = null;
                        }
                    });
                })
                .catch((err) => {
                    throw err;
                });
        },
        syncProcessCateName(item) {
            // 判断item中是否存在process_cate_name 属性,如果不存在则不进行处理
            if ('process_cate_name' in item) {
                const process = item.product_info?.craft.find(craft => {
                    return craft.name === item.process_name;
                });
                if (process) {
                    item.process_cate_name = process.cate_name;
                }
            }
        },
        async syncMachineInfo(localItems) {
            const remoteMacs = await this.fetchMachines();
            localItems.forEach(item => {
                const remoteMac = remoteMacs.data.filter((mac) => {
                    return (mac.code === item.machine || mac.name === item.machine);
                });
                if (remoteMac.length > 0) {
                    const code = remoteMac[0].code;
                    const id = remoteMac[0].id;
                    const name = remoteMac[0].name;
                    item.machine_info = {code, id, name};
                } else {
                    item.machine_info = null;
                }
            });
        },
        updateMacErrorTags(item) {
            this.updateErrorTags(item.error, ErrorTags['mac-not-found'],
                item.machine_info === null);
        },
        removeError(errors, tagToRemove) {
            for (let i = errors.length - 1; i >= 0; i--) {
                if (errors[i].tag === tagToRemove) {
                    errors.splice(i, 1);
                }
            }
        },
        addError(errors, tag, info) {
            errors.push({tag, info});
        },
        updateErrorTags(errors, {tag, info}, isError) {
            this.removeError(errors, tag);
            if (isError) {
                // 添加新的错误标签
                this.addError(errors, tag, info);
            }
        },
        getItem(serial_number, property) {
            return this[property].find((item) => {
                return item.serial_number === serial_number;
            });
        },
        getOrder(serial_number) {
            return this.getItem(serial_number, 'orderList');
        },
        getJob(serial_number) {
            return this.getItem(serial_number, 'jobList');
        },
        getSchd(serial_number) {
            return this.getItem(serial_number, 'schdList');
        },
        getSchdHistory(serial_number) {
            return this.getItem(serial_number, 'schdHistory');
        },
        beforeAddLockCheck(toLock) {
            // 获取已有的locks信息
            // 根据job_code,machine_info.id,product_info.id,process_name
            const fmt = 'HH:mm';
            const locks = this.getLocks(toLock);
            const toLockPre = dayjs(toLock.pre, fmt);
            const toLockEnd = dayjs(toLock.end, fmt);
            if (locks.length > 0) {
                // 比对时间起始范围是否存在交叉
                for (let i = 0; i < locks.length; i++) {
                    const lockPre = dayjs(locks[i].pre, fmt);
                    const lockEnd = dayjs(locks[i].end, fmt);
                    if (!lockEnd.isValid()) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},占据相应的时间段`, isError: true};
                    }
                    // end 或者 pre在lockPre,lockEnd之间存在交叉
                    if (toLockPre.isBefore(lockEnd) && toLockEnd.isAfter(lockPre)) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},占据相应的时间段`, isError: true};
                    }
                    // 判断任务单号是否重复
                    if (toLock.job_code === locks[i].job_code) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},同一天不要重复锁定`, isError: true};
                    }
                }
            }
            // 如果有冲突则返回错误信息
            return {msg: '', isError: false};
        },
        /**
         * @param {string|Date|null} plan_date
         * @returns {calcPostData}
        */
        async mkCalcPostTPl(plan_date) {
            if (!dayjs(plan_date).isValid()) throw Error('计划日期格式错误!');
            const _pdate = dayjs(plan_date).format('YYYY-MM-DD');
            // if (dayjs(_pdate).isBefore(dayjs(), 'day')) {
            //     throw Error('计划日期不能小于当前日期');
            // }
            const calcPost = {};
            calcPost.options = {
                '开始日期': _pdate,
                '最小时长': 10,
                // 计算的最大时长,单位秒
                'max-time': this.taskRunMaxSec,
            };
            // TODO:目前只支持印刷工序
            const validProcessNames = ['印刷'];
            calcPost.categories = ['print'];
            const schd = this.getSchdLastCode(plan_date);
            const remoteMacs = await this.fetchMachines();
            calcPost.machines = this.mkCalcPostMachines(remoteMacs, schd);
            calcPost.jobs = this.mkPostJobs().filter((item) => {
                return validProcessNames.indexOf(item.process_cate_name) > -1;
            });
            calcPost.locks = this.mkPostLocks2(calcPost.jobs);
            console.log('post-calc', calcPost);
            if (calcPost.jobs.length === 0) {
                throw Error('没有相应的任务信息');
            }
            // return calcPost;
            return stringify(calcPost);
        },
        /**
         * @returns {Object<string, {last_code: (string|null)}}
         */
        mkCalcPostMachines(remoteMacs, schd) {
            // 获取所有机台信息
            const calcPostMac = remoteMacs.data.reduce((pre, cur) => {
                pre[cur.code] = {'last_code': schd[cur.code] || null};
                return pre;
            },
            {});
            return calcPostMac;
        },
        getSchdLastCode(plan_date) {
            // 获取schdList中机台中的最后一个排产单号,只计算有效

            const schds = this.schdList.filter((item) => {
                return item.error.length === 0 && dayjs(item.date).isBefore(dayjs(plan_date));
            });
            const gByMacCode = {};
            for (const schd of schds) {
                if (schd.machine_info) {
                    if (!gByMacCode[schd.machine_info.code]) {
                        gByMacCode[schd.machine_info.code] = [];
                    }
                    gByMacCode[schd.machine_info.code].push(schd);
                }
            }
            const result = {};
            for (const [machine, entries] of Object.entries(gByMacCode)) {
                const latestEntry = entries.reduce((maxEntry, currentEntry) => {
                    const _maxd = dayjs(`${maxEntry.date} ${maxEntry.end}`);
                    const _cred = dayjs(`${currentEntry.date} ${currentEntry.end}`);
                    return _maxd.isAfter(_cred) ? maxEntry : currentEntry;
                },
                );
                result[machine] = `${latestEntry.product_info?.code}::${latestEntry.process_name}`;
            }

            return result;
        },
        /**
         *
         * @returns {Promise<machine[]>}
         */
        async fetchMachines() {
            const url = `/api/machines/`;
            try {
                const res = await axios.get(url);
                if (res.data.error) {
                    throw new Error(res.data.error);
                }
                return res.data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // 处理 Axios 错误
                    console.error('Axios Error:', error.message);
                }
                throw error;
            }
        },
        /**
         * @returns {Job[]}
         */
        mkPostJobs() {
            return this.jobList.filter(this.isJobPostEnabled).map(job => {
                const quantity = Number(job.quantity);
                let produced_quantity = 0;
                if (!isNaN(Number(job.produced_quantity))) {
                    produced_quantity = Number(job.produced_quantity);
                }
                console.log(job);
                // 根据 g_plan_date来处理相应的任务信息
                // 1 交期在g_plan_date之前

                const {earliest, latest} = this.calcDateRange(job);
                return {
                    code: job.job_code,
                    product_code: job.product_info?.code || job.product,
                    process_code: job.process_name,
                    process_name: job.process_name,
                    quantity: Math.floor(quantity - produced_quantity),
                    process_cate_name: job.process_cate_name,
                    earliest,
                    latest,
                };
            });
        },
        /**
         * 根据计划交期计算最早最迟日期
         * @param {Job} job
         * @returns {{earliest: string, latest: string}}
         */
        calcDateRange({shipment_date, job_code}) {
            const _s = dayjs(shipment_date);
            if (!_s.isValid()) throw Error(`任务${job_code}的计划交期期格式错误!`);
            // 1 shipment 不在g_plan_date与计算日期之间
            const g = dayjs(this.g_plan_date);
            if (g.diff(_s, 'day') > 0 || _s.diff(g, 'day') > this.calculationDays) {
                return {
                    earliest: g.format('YYYY-MM-DD'),
                    latest: g.add(this.calculationDays, 'day').format('YYYY-MM-DD'),
                };
            }
            return {
                earliest: g.format('YYYY-MM-DD'),
                latest: _s.format('YYYY-MM-DD'),
            };
        },
        isJobPostEnabled(job) {
            // 如果标记为完成则不参与计算
            if (job.is_finished !== '否') return false;
            // 没有错误信息
            if (job.error.length > 0) return false;
            // 标记为不参与计算
            if (!job.isIncludedInCalculation) return false;
            // 数量不是数值类型
            const quantity = Number(job.quantity);
            if (isNaN(quantity)) return false;
            // 数量小于完成量
            const produced_quantity = Number(job.produced_quantity);
            if (!isNaN(produced_quantity) && quantity <= produced_quantity) {
                return false;
            }
            return true;
        },
        /**
         * @returns {LockInfo[]}
         */
        mkPostLocks(lockList) {
            // 对机台日期进行分组
            const gLock = lockList.reduce((acc, job) => {
                if (`${job.job_code}::${job.machine}` in acc) {
                    acc[`${job.job_code}::${job.machine}`].push(job);
                } else {
                    acc[`${job.job_code}::${job.machine}`] = [job];
                }
                return acc;
            }
            , {});
            // 对gLock进行转换
            const locks = [];
            // 对gLock中的每个job按照日期和pre进行排序
            Object.values(gLock).forEach(jobs => {
                const mapped = jobs.map((job, i) => {
                    return {
                        i,
                        value:
                        dayjs(`${dayjs(job.date).format('YYYY-MM-DD')} ${job.pre}`, 'YYYY-MM-DD HH:mm'),
                    };
                });
                mapped.sort((a, b) => {
                    if (a.value.isAfter(b.value)) {
                        return 1;
                    }
                    if (a.value.isBefore(b.value)) {
                        return -1;
                    }
                    return 0;
                });

                const sortedJobs = mapped.map((v) => jobs[v.i]);
                if (sortedJobs.length > 0) {
                    const _job = {};
                    _job.schedule = sortedJobs.map((job) => {
                        return {
                            pre: job.pre,
                            run: job.run,
                            end: job.end,
                            date: dayjs(job.date).format('YYYY-MM-DD'),
                        };
                    });
                    _job.job_code = sortedJobs[0].job_code;
                    _job.machine = sortedJobs[0].machine_info.code;
                    locks.push(_job);
                }
            });
            return locks;
        },
        /**
         * @param {Job[]} jobs
         * @returns {LockInfo[]}
         */
        mkPostLocks2(jobs) {
            const locksAcc = this.schdList.filter((item) => {
                return item.is_locked === '是' && jobs.find((job) => job.code === item.job_code);
            });
            const locksHis = this.schdHistory.filter((item) => {
                return item.is_locked === '是' && jobs.find((job) => job.code === item.job_code);
            });
            // job_code相同以及date相同则只保留locksAcc中的数据
            const locks = [...locksAcc];
            for (const h of locksHis) {
                if (locks.find(h1 => h1.job_code === h.job_code && h.date === h1.date)) continue;
                locks.push(h);
            }
            // 清除lockInfo
            this.LockInfoList.length = 0;
            if (locks.length === 0) return [];
            const lockInfo = this.mkPostLocks(locks);
            this.LockInfoList.push(...lockInfo);
            return lockInfo;
        },
        addRunningTask(uuid) {
            this.currentTaskId = uuid;
            this.taskStart = dayjs();
        },
        resetRunningTask() {
            this.currentTaskId = null;
            this.taskStart = null;
        },
        async checkAll() {
            await this.syncProduct(this.orderList);
            await this.syncProduct(this.schdList);
            await this.syncProduct(this.jobList);
            this.syncScheduleSummary(this.jobList);
            await this.syncMachineInfo(this.jobList);
            await this.syncMachineInfo(this.schdList);
            this.checkOrder(this.orderList);
            this.checkJob(this.jobList);
            this.checkSchd(this.schdList);
        },
    },
    // persist: {
    //     type: 'storage',
    //     name: 'Vue3PersistStorage',
    //     storeName: 'DataSheet',
    //     key: 'schd-local',
    //     paths: ['orderList', 'jobList',
    //         'schdList', 'g_plan_date', 'currentTaskId',
    //         'taskStart', 'taskRunMaxSec', 'storeKey'],
    //     encryption: false,
    //     isOpenExpires: true,
    //     day: 1,
    //     // beforeRestore: () => {
    //     //     console.log('before resotre');
    //     //     ElLoading.service({fullscreen: true});
    //     // },
    //     afterRestore: (plug) => {
    //         plug.store.checkAll();
    //     },
    //     debug: false,
    // },
    persist: {
        storage: localStorage,
        key: 'schd-local',
        paths: ['orderList', 'jobList', 'schdHistory',
            'schdList', 'g_plan_date', 'currentTaskId',
            'taskStart', 'taskRunMaxSec', 'storeKey'],
        // beforeRestore: (ctx) => {
        //     console.log(ctx.store.jobList);
        //     console.log('before,restore');
        //     // ctx.store.checkAll();
        // },
        afterRestore: (ctx) => {
            if (ctx.store.orderList.length > 0) {
                ctx.store.checkAll();
            }
        },
    },
});

export default SchdStore;
export {SchdStore};
